import { Services_Data } from "./Data";
import CustomArrow from './CustomArrow';
import Slider from "react-slick";
import ScrollAnimation from "react-animate-on-scroll";

function Services() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <CustomArrow direction="next" />,
        prevArrow: <CustomArrow direction="prev" />,
    };
    return (
        <>
            <ScrollAnimation
                animateIn="animate__fadeInUp"
                animateOnce='treu'>
                <div className=" flex flex-col my-10 text-center">
                    <p className="text-[32px] text-mainYellow font-bold">خدماتنا المميزة</p>
                    <div className="flex gap-2 justify-center text-xl">
                        <h1> تدعم خدماتنا </h1>
                        <h1 className="font-black"> المنشآت بكافة أنشطتها لتكون </h1>
                    </div>
                    <div className="flex gap-2 justify-center text-2xl font-black">
                        <ScrollAnimation
                            animateIn="animate__fadeInDown"
                            animateOnce='true'
                            delay={150}>
                            <h1> منظمة،،، </h1>
                        </ScrollAnimation>
                        <ScrollAnimation
                            animateIn="animate__fadeInDown"
                            animateOnce='true'
                            delay={750}>
                            <h1 className="text-mainYellow"> آمنة،،، </h1>
                        </ScrollAnimation>
                        <ScrollAnimation
                            animateIn="animate__fadeInDown"
                            animateOnce='true'
                            delay={1250}>
                            <h1> محمية،،، </h1>
                        </ScrollAnimation>
                    </div>

                </div>
            </ScrollAnimation>
            <ScrollAnimation
                animateIn="animate__fadeInUp"
                animateOnce='treu'>
                <div className="w-11/12 mx-auto">
                    <Slider {...settings}>
                        {Services_Data.map((d) => (

                            <div className="text-center border-2 rounded-2xl">
                                <div className="flex justify-center my-10">
                                    <img className="w-14 h-14  opacity-95" src={d.sImage} alt="Consultation" />
                                </div>
                                <p className="w-full  text-black text-3xl font-bold mb-5">{d.sName}</p>
                                <p className="text-gray-600 text-xl px-2 mb-10">{d.sDesc}</p>

                            </div>
                        ))}
                    </Slider>

                </div>
            </ScrollAnimation>

        </>
    );
}

export default Services