import ScrollAnimation from 'react-animate-on-scroll'
import client01 from '../images/client_01getsmartcenter.jpeg'
import client02 from '../images/client_02ihcc.png'
import client03 from '../images/client_03alkimam.png'
import client04 from '../images/client_04abudawood.png'
import client05 from '../images/client_05drFakeeh.png'
import client06 from '../images/client_06nahdi.png'
import client07 from '../images/client_07theheadquarter2.png'
import client08 from '../images/client_08uptown.png'


function OurClients() {

    return (
        <>
            <div className="container flex flex-col items-center justify-center my-32 mx-auto mb-10">
                <ScrollAnimation
                    animateIn="animate__bounceInLeft"
                    delay={250}
                    animateOnce={true}
                    animatePreScroll={false}
                >
                    <h2 className="text-3xl font-semibold ">عملاءنا</h2>
                </ScrollAnimation>

                <ScrollAnimation animateIn="animate__bounceInRight"
                    delay={350}
                    animateOnce={true}>
                    <p className="text-gray-600 text-xl mt-4 text-center">
                    نجاحنا يعود إلى علاقتنا المستدامة والقائمة على الثقة، حيث نعمل مع عملاءنا المتميزون لتحقيق أهدافنا المشتركة وتقديم أرقى الخدمات !!!
                    </p>
                </ScrollAnimation>
            </div>
            <div
                //             x-data="{}"
                //             x-init="$nextTick(() => {
                //     let ul = $refs.logos;
                //     ul.insertAdjacentHTML('afterend', ul.outerHTML);
                //     ul.nextSibling.setAttribute('aria-hidden', 'true');
                // })"
                //             class="w-full inline-flex flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-200px),transparent_100%)]">
                //             <ul x-ref="logos" class="flex items-center justify-center md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll">

                class="w-full inline-flex  overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-200px),transparent_100%)]">
                <ul class="flex md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll">
                    <li>
                        <img className='h-32 w-32' src={client01} alt="client01" />
                    </li>
                    <li>
                        <img className='h-32 w-32' src={client02} alt="lient02" />
                    </li>
                    <li>
                        <img className='h-32 w-32' src={client03} alt="client03" />
                    </li>
                    <li>
                        <img className='h-32 w-32' src={client04} alt="client04" />
                    </li>
                    <li>
                        <img className='h-32 w-32' src={client05} alt="client05" />
                    </li>
                    <li>
                        <img className='h-32 w-32' src={client06} alt="client06" />
                    </li>
                    <li>
                        <img className='h-32 w-32' src={client07} alt="client07" />
                    </li>
                    <li>
                        <img className='h-32 w-32' src={client08} alt="client08" />
                    </li>
                </ul>
                <ul class="flex md:justify-start [&_li]:mx-8 [&_img]:max-w-none animate-infinite-scroll" aria-hidden="true">
                    <li>
                        <img className='h-32 w-32' src={client01} alt="client01" />
                    </li>
                    <li>
                        <img className='h-32 w-32' src={client02} alt="lient02" />
                    </li>
                    <li>
                        <img className='h-32 w-32' src={client03} alt="client03" />
                    </li>
                    <li>
                        <img className='h-32 w-32' src={client04} alt="client04" />
                    </li>
                    <li>
                        <img className='h-32 w-32' src={client05} alt="client05" />
                    </li>
                    <li>
                        <img className='h-32 w-32' src={client06} alt="client06" />
                    </li>
                    <li>
                        <img className='h-32 w-32' src={client07} alt="client07" />
                    </li>
                    <li>
                        <img className='h-32 w-32' src={client08} alt="client08" />
                    </li>
                </ul>
            </div>
        </>
    );
}

export default OurClients