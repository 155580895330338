import React, { useState } from 'react'
import { FaBars, FaTimes } from 'react-icons/fa'
import logo from '../images/logo.png'

import './Navbar.css'

const Navbar = () => {

  const [click, setClick] = useState(false)
  const handleClick = () => setClick(!click)

  const closeMenu = () => setClick(false)

  return (
    <div className='header'>

      <nav className='navbar'>

        <a href='/' className='h-20 w-20'>
          <img src={logo} alt='logo' />
        </a>

        <div className='hamburger' onClick={handleClick}>
          {click ? (<FaTimes size={30} style={{ color: '#ffffff' }} />)
            : (<FaBars size={30} style={{ color: '#ffffff' }} />)}

        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className='nav-item'>
            <a href='/' onClick={closeMenu}>الرئيسية</a>
          </li>
          <li className='nav-item'>
            <a href='#about' onClick={closeMenu}>من نحن؟</a>
          </li>
          <li className='nav-item'>
            <a href='#testimonials' onClick={closeMenu}>خدماتنا</a>
          </li>
          <li className='nav-item'>
            <a href='#demo' onClick={closeMenu}>المقالات</a>
          </li>
          <li className='nav-item'>
            <a href='#demo' onClick={closeMenu}>عملائنا</a>
          </li>
        </ul>

      </nav>
    </div>
  )
}

export default Navbar
