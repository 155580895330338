import Navbar from './components/Navbar';
import Hero from './components/Hero';
import OurHistory from './components/OurHistory'
import Footer from './components/Footer';
import HemayatInAction from './components/HemayatInAction';
import Services from './components/Services';
// import AboutUs from './components/AboutUs';
import About from './components/About';
import OurClients from './components/OurClients';
import OurNumbers from './components/OurNumbers';
import ContactUs from './components/ContactUs';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Hero />
      <Services />
      <About />
      <OurNumbers />
      <OurClients />
      <OurHistory />
      <ContactUs />
      <Footer />
    </div>
  );

}

export default App;
