import imgTest from '../images/secBuild.png'
import vision from '../images/vision.png'
import mission from '../images/mission.png'
import goals from '../images/goals.png'
import ScrollAnimation from 'react-animate-on-scroll';
import target_aboutUS from '../images/target_aboutUS.png'
import value_aboutUS from '../images/value_aboutUS.png'
import vision_aboutUS from '../images/vision_aboutUS.png'
import falcon from '../images/falcon.png'

function About() {
    return (
        <>
            <div className="container flex flex-col items-center justify-center my-32 mx-auto mb-10">
                <ScrollAnimation
                    animateIn="animate__bounceInLeft"
                    delay={250}
                    animateOnce={true}
                    animatePreScroll={false}
                >
                    <h2 className="text-3xl font-semibold ">عن الحمايات؟</h2>
                </ScrollAnimation>

                <ScrollAnimation animateIn="animate__bounceInRight"
                    delay={350}
                    animateOnce={true}>
                    <p className="text-gray-600 text-xl my-10 text-center">
                        نجاحنا يعود إلى شراكاتنا المستدامة والقائمة على الثقة، حيث نعمل مع شركاء متميزين لتحقيق أهدافنا المشتركة وتقديم حلول مبتكرة !!!
                    </p>
                </ScrollAnimation>
            </div>
            <div className='relative border-2 p-2 mx-2'>

                <div className='p-4 flex flex-col gap-3'>
                    <h1 className='font-bold text-3xl text-mainYellow'>من نحن؟</h1>
                    <p className='w-1/2 text-2xl my-2'>شركة الحمايات الأمنة المحدودة هي إحدى شركات سيسبان القابضة وقد نجحت منذ إنشائها في العديد من المجالات بما فيها المستشفيات والمكاتب والمباني التجارية.....</p>
                    <button className="bg-mainYellow w-1/4 text-white py-1 px-8">اعرف المزيد</button>
                    <hr className='border-[0.1rem] my-20' />
                </div>
                <div className="absolute left-0 bottom-0 w-1/2">
                    <img className='opacity-10 w-full' src={falcon} />
                </div>
                <div className="flex justify-between gap-4 mb-20">

                    <div className="flex gap-2 flex-col items-center">
                        <img className='w-20 h-20' src={vision_aboutUS} />
                        <h1 className="font-bold text-xl">الرؤية</h1>
                        <h1 className='text-center w-9/12'>مجموعة تقدم خدمات أمنية شاملة ذات معايير وجوده عالية</h1>
                    </div>

                    <div className="flex gap-2 w-1/4 flex-col items-center">
                        <img className='w-20 h-20 ' src={value_aboutUS} />
                        <h1 className="font-bold text-xl">الرسالة</h1>
                        <h1 className='text-center w-11/12'>الارتقاء بالخدمات الأمنية بالمملكة لتنافس الخدمات الأمنية في الدول المتقدمة</h1>
                    </div>

                    <div className="flex gap-2 flex-col items-center ">
                        <img className='w-20 h-20' src={target_aboutUS} />
                        <h1 className="font-bold text-xl"> الرؤية</h1>
                        <div className='flex flex-col gap-3'>
                            <div className='flex justify-start items-center'>
                                <div className='h-6 w-6 ml-4 rounded-full bg-mainYellow'></div>
                                <h1 className='text-right'>مواكبة رؤية المملكة 2030 في قطاع خدمات الأمن المدني</h1>
                            </div>
                            <div className='flex justify-start items-center'>
                                <div className='h-6 w-6 rounded-full bg-mainYellow'></div>
                                <h1 className='text-right mr-4'>تقديم خدمات أمنية شاملة وفق معايير عالمية</h1>
                            </div>
                            <div className='flex justify-start items-center'>
                                <div className='h-6 w-6 ml-4 rounded-full bg-mainYellow'></div>
                                <h1 className='ml-4'>رضاء العملاء وشعورهم بالأمن والأمان بالخدمات المقدمة</h1>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </>
    );
}

export default About