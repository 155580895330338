import React from "react";
import 'animate.css'
import ScrollAnimation from 'react-animate-on-scroll';
import Lottie from "react-lottie";
import seeding from '../assets/seeding.json'
import rocket from '../assets/rocket_Animation.json'
import planning from '../assets/planning_Animation.json'
import creativity from '../assets/creativity.json'

function OurHistory() {
    const seeding_Animation = {
        loop: true,
        autoplay: true,
        animationData: seeding,
    };

    const rocket_Animation = {
        loop: true,
        autoplay: true,
        animationData: rocket,
    };
    const planning_Animation = {
        loop: true,
        autoplay: true,
        animationData: planning,
    };

    const creativity_Animation = {
        loop: true,
        autoplay: true,
        animationData: creativity,
    };

    return (

        <>
            <div className="container flex flex-col items-center justify-center my-32 mx-auto mb-10">
                <ScrollAnimation
                    animateIn="animate__bounceInLeft"
                    delay={250}
                    animateOnce={true}
                    animatePreScroll={false}
                >
                    <h2 className="text-3xl font-semibold text-mainYellow ">تاريخنا</h2>
                </ScrollAnimation>

                <ScrollAnimation animateIn="animate__bounceInRight"
                    delay={350}
                    animateOnce={true}>
                    <p className="text-gray-600 mt-4 text-center">
                        على مدى السنوات الثلاث الماضية، حققت شركة الحميات المحدودة إنجازات كبيرة ونموًا كبيرًا.
                    </p>
                </ScrollAnimation>
            </div>
            <div class="max-w-7xl mx-auto w-full grid grid-cols-9 px-2 text-center">

                {/* <!-- Stack 1 --> */}
                <div class="col-span-4 ">
                    <ScrollAnimation
                        animateIn="animate__bounceInRight"
                        delay={350}
                        animateOnce={true}>

                        <div class="w-full h-full bg-[#204485] rounded-md p-2 md:pl-4">
                            <h1 class="text-[#CBAD4C] text-xl font-medium py-2">2020</h1>
                            <p class="text-gray-100 sm:text-sm text-xs ">قمنا بتأسيس الشركة وكان طموحنا أن نحقق أعلى الأهداف في كل ما يتعلق بقطاع الحمايات الأمنية.</p>
                        </div>
                    </ScrollAnimation>
                </div>

                <div class="relative col-span-1  h-full flex justify-center items-center">
                    <div class="h-full w-2 bg-[#204485]"></div>
                    <div class="absolute w-6 h-6 rounded-full bg-[#204485] z-10 text-white text-center">1</div>
                </div>

                <div className=" col-span-4 flex items-center">
                    <ScrollAnimation
                        animateIn="animate__bounceInRight"
                        animateOnce="true">
                        <h1 className="text-black">التأسيس</h1>
                    </ScrollAnimation>
                    <ScrollAnimation
                        animateIn="animate__bounceInRight"
                        animateOnce="true">
                        <div>
                            <Lottie options={seeding_Animation} height={120} width={120} speed={0.5} />
                        </div>
                    </ScrollAnimation>
                </div>

                {/* <!-- Stack 2 --> */}
                <div className=" col-span-4 flex justify-end w-full items-center">
                    <div >
                        <ScrollAnimation
                            animateIn="animate__bounceInRight"
                            animateOnce="true">

                            <Lottie options={rocket_Animation} height={100} width={100} speed={0.5} />
                        </ScrollAnimation>
                    </div>
                    <ScrollAnimation
                        animateIn="animate__bounceInRight"
                        animateOnce="true">
                        <h1 className="text-black">الإنطلاق</h1>
                    </ScrollAnimation>
                </div>
                <div class="relative col-span-1 w-full h-full flex justify-center items-center">
                    <div class="h-full w-2 bg-[#204485]"></div>
                    <div class="absolute w-6 h-6 rounded-full bg-[#204485] z-10 text-white text-center">2</div>
                </div>

                <div class="col-span-4 w-full h-full ">
                    <ScrollAnimation animateIn="animate__bounceInLeft"
                        delay={500}
                        animateOnce={true}>
                        <div class="w-full h-full bg-[#204485] rounded-md p-2 md:pl-4">
                            <h1 class="text-[#CBAD4C] text-xl font-medium py-2">2021</h1>
                            <p class="text-gray-100 sm:text-sm text-xs ">قطعنا شوطاً كبيراً من خلال التعاقد والعمل مع شركاء نجاح متميزون في قطاعاتهم ومجالاتهم وطمحنا للمزيد ...</p>
                        </div>
                    </ScrollAnimation>
                </div>

                {/* <!-- Stack 3 --> */}
                <div class="col-span-4 w-full h-full ">
                    <ScrollAnimation
                        animateIn="animate__bounceInRight"
                        delay={750}
                        animateOnce={true}
                    >

                        <div class="w-full h-full bg-[#204485] rounded-md p-2 md:pl-4">
                            <h1 class="text-[#CBAD4C] text-xl font-medium py-2">2022</h1>
                            <p class="text-gray-100 sm:text-sm text-xs">رسمنا خطة المستقبل، وقائمون بتنفيذها على أكمل وجه يرضي عملائنا، بكوادر احترافية، وطموحات عالية.</p>
                        </div>
                    </ScrollAnimation>
                </div>
                <div class="relative col-span-1 w-full h-full flex justify-center items-center">
                    <div class="h-full w-2 bg-[#204485]"></div>
                    <div class="absolute w-6 h-6 rounded-full bg-[#204485] z-10 text-white text-center">3</div>
                </div>
                <div className=" col-span-4 flex items-center">
                    <ScrollAnimation
                        animateIn="animate__bounceInLeft"
                        animateOnce="true">
                        <h1 className="text-black">التخطيط</h1>
                    </ScrollAnimation>
                    <ScrollAnimation
                        animateIn="animate__bounceInLeft"
                        animateOnce="true">
                        <div>
                            <Lottie options={planning_Animation} height={100} width={100} speed={0.5} />
                        </div>
                    </ScrollAnimation>
                </div>
                {/* <!-- Stack 2 --> */}
                <div className=" col-span-4 flex justify-end w-full items-center">

                    <ScrollAnimation
                        animateIn="animate__bounceInRight"
                        animateOnce="true">
                        <div >
                            <Lottie options={creativity_Animation} height={100} width={100} speed={0.5} />
                        </div>
                    </ScrollAnimation>
                    <ScrollAnimation
                        animateIn="animate__bounceInRight"
                        animateOnce="true">
                        <h1 className="text-black">الإبداع</h1>
                    </ScrollAnimation>
                </div>
                <div class="relative col-span-1 w-full h-full flex justify-center items-center">
                    <div class="h-full w-2 bg-[#204485] rounded-b-md"></div>
                    <div class="absolute w-6 h-6 rounded-full bg-[#204485] z-10 text-white text-center">4</div>
                </div>

                <div class="col-span-4 w-full h-full ">
                    <ScrollAnimation animateIn="animate__bounceInLeft"
                        delay={500}
                        animateOnce={true}>
                        <div class="w-full h-full bg-[#204485] rounded-md p-2 md:pl-4">
                            <h1 class="text-[#CBAD4C] text-xl font-medium py-2">2023</h1>
                            <p class="text-gray-100 sm:text-sm text-xs ">قطعنا شوطاً كبيراً من خلال التعاقد والعمل مع شركاء نجاح متميزون في قطاعاتهم ومجالاتهم وطمحنا للمزيد ...</p>
                        </div>
                    </ScrollAnimation>
                </div>
            </div>
        </>
    );
}

export default OurHistory