import ScrollAnimation from "react-animate-on-scroll"
import 'animate.css'
import { OurNumbers_Data } from "./Data"
import { useSpring, animated } from "react-spring";
import { useInView } from 'react-intersection-observer';

const AnimatedNumber = ({ targetValue }) => {
    const [ref, inView] = useInView({
        triggerOnce: true,
    });

    const props = useSpring({
        delay: 200,
        config: { mass: 1, tension: 25, friction: 20 },
        value: inView ? targetValue : 0,
        from: { value: 0 },
    });

    return (
        <animated.span ref={ref}>
            {props.value.to((n) => n.toFixed(0))}
        </animated.span>
    );
};


function OurNumbers() {

    return (
        <div className="my-32">
            <div className="container flex flex-col items-center justify-center mx-auto mb-10">
                <ScrollAnimation
                    animateIn="animate__bounceInLeft"
                    delay={250}
                    animateOnce={true}
                    animatePreScroll={false}
                >
                    <h2 className="text-3xl font-semibold text-mainYellow ">أرقام الحمايات</h2>
                </ScrollAnimation>

                <ScrollAnimation animateIn="animate__bounceInRight"
                    delay={350}
                    animateOnce={true}>
                    <p className="text-gray-600 mt-4 text-center">
                        نحن نفتخر بتقديم حلول الحراسات الأمنية بأرقام تعكس التزامنا بالجودة والخدمة المتميزة، إليك بعض الأرقام التي حققناها ،،،
                    </p>
                </ScrollAnimation>
            </div>
            <div className="flex justify-center gap-28">
                {OurNumbers_Data.map((d) => (
                    <div id="yourTargetDivId" className="flex gap-2 flex-col items-center">
                        <img src={d.img} />
                        <h1 className="font-bold text-3xl">
                            <AnimatedNumber targetValue={d.numbers} />
                        </h1>
                        <h1 className="font-bold text-xl" style={d.style}>{d.title}</h1>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default OurNumbers
