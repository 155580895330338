import React from 'react';


const CustomArrow = ({ onClick, direction }) => {
  return (
    <button
      className={`${
        direction === 'prev' ? '-left-10' : '-right-10'
      } absolute font-black text-6xl top-1/2 transform -translate-y-1/2  text-black  rounded-full`}
      onClick={onClick}
    >
      {direction === 'prev' ? '<' : '>'}
    </button>
  );
};

export default CustomArrow;
