import secConsalt from '../images/secConsalt_yellow.png'
import secStudy from '../images/da_blue.png'
import secPersons from '../images/fa_yellow.png'
import secProps from '../images/re_blue.png'
import secMaterials1 from '../images/cctv_yellow.png'
import secMaterials2 from '../images/cy_blue.png'
import emps from '../images/emps.png'
import comps from '../images/comps.png'
import locations from '../images/locations.png'
import emailIcon from '../images/email.png'
import telephoneIcon from '../images/telephone.png'
import phoneIcon from '../images/phone.png'



const Services_Data = [
    {
        sName: "الإستشارات الأمنية",
        sDesc: "تقدم الشركة خدمات الاستشارات الأمنية المتميزة وتقديم توصيات وخطط أمنية دقيقة لتحسين الأمان وتعزيز الحماية للعملاء",
        sImage: secConsalt
    },
    {
        sName: "الدراسات الأمنية",
        sDesc: "تقوم الشركة بإجراء دراسات أمنية متخصصة لتحديد المخاطر وتحليل الضعف والقوة في نظام الأمان للعملاء وتقديم حلول فعالة",
        sImage: secStudy
    }, {
        sName: "حماية الشخصيات",
        sDesc: "تقدم الشركة خدمات حماية مخصصة للشخصيات الهامة والمهمة باستخدام أحدث التقنيات",
        sImage: secPersons
    },
    {
        sName: "حماية المرافق",
        sDesc: "تقوم الشركة بإجراء دراسات أمنية متخصصة لتحديد المخاطر وتحليل الضعف والقوة في نظام الأمان للعملاء وتقديم حلول فعالة",
        sImage: secProps
    },
    {
        sName: "أجهزة الحماية الأمنية",
        sDesc: "تقدم الشركة مجموعة كبيرة من أجهزة الأمن والسلامة لرفع مستوى الحماية وتحقيق السلامة الدائمة للمنشآت والمرافق",
        sImage: secMaterials1
    },
    {
        sName: "الأجهزة الأمنية",
        sDesc: "تقدم الشركة مجموعة كبيرة من أجهزة الأمن والسلامة لرفع مستوى الحماية وتحقيق السلامة الدائمة للمنشآت والمرافق",
        sImage: secMaterials2
    }
]

const OurNumbers_Data = [
    {
        img: emps,
        title: 'الموظفين',
        numbers: 185,
        style: { color: '#CBAD4C' }
    },
    {
        img: comps,
        title: 'الشركات',
        numbers: 92,
        style: { color: '#4C6CCB' }

    },
    {
        img: locations,
        title: 'المواقع',
        numbers: 250,
        style: { color: '#CBAD4C' }
    }

]

const ContactUs_Data = [
    {
        title: "البريد الإلكتروني:",
        data: "info@alhemayat.com",
        icon: emailIcon
    },
    {
        title: "الهاتف:",
        data: "966126531267+",
        icon: telephoneIcon
    },
    {
        title: "رقم الجوال:",
        data: "966500039115+",
        icon: phoneIcon
    }
]

export { Services_Data, OurNumbers_Data, ContactUs_Data };