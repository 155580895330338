import { useRef } from 'react';
import { ContactUs_Data } from './Data';
import ScrollAnimation from 'react-animate-on-scroll';

const ContactUs = () => {
    const form = useRef();
    return (
        // the blue backGround colors are so good but it's not matching this site
        // from-blue-500 to-red-500
        <ScrollAnimation
            animateIn='animate__fadeInUp'
            animateOnce='true' >
            <div className=' my-32 bg-gradient-to-r from-mainYellow from-30% to-gray-100 to-30% rounded-3xl mx-5'>
                <div className='flex justify-between gap-20 py-10 px-20'>
                    <div className='flex flex-col'>
                        <h1 className='font-black text-2xl'>إبق على تواصل</h1>
                        <p className='text-lg mb-10'>نحن هنا لتقديم أفضل الحلول الأمنية، ثق بنا، وتأكد أن تواصلك معنا هو أفضل <span className="text-mainYellow">قرار </span>،،،</p>
                        <form ref={form} className="flex flex-col mb-10 col-span-1 w-full items-center gap-4">
                            <input required className=" outline-none focus:border-main text-main border-2 pr-2 w-full h-12 text-right" placeholder="الإسم" name="from_name" />
                            <input className=" outline-none focus:border-main text-main border-2 pr-2  w-full h-12 text-right" placeholder="البريد الإلكتروني" name="from_email" />
                            <input className=" outline-none focus:border-main text-main border-2 pr-2  w-full h-12 text-right" placeholder="رقم الجوال" name="phone" />
                            <button className="bg-mainYellow w-full text-white py-1 px-8" type="submit" value="Send" >إرسال</button>
                        </form>
                        <div className='flex justify-center'>
                            {ContactUs_Data.map((d) => (
                                <div className='flex justify-center gap-1 mx-1'>
                                    <div className='flex flex-col'>
                                        <h1 className='font-bold text-center'>{d.title}</h1>
                                        <p className='text-mainYellow'>{d.data}</p>
                                    </div>
                                    <div className='flex items-center'>
                                        <img className='h-7 w-7' src={d.icon} />
                                    </div>
                                </div>

                            ))}
                        </div>
                    </div>
                    <div>
                        <iframe className='border-2 border-black' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3711.5588857792254!2d39.165118373813655!3d21.52499147055292!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15c3cfecfc803cad%3A0xba8ac50554feef9d!2sDr.%20Soliman%20Fakeeh%20Hospital!5e0!3m2!1sen!2ssa!4v1703753204859!5m2!1sen!2ssa" width="500" height="430" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </div>
        </ScrollAnimation>

    )
}

export default ContactUs;