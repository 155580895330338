import logo from '../images/logo.png'
import twiter from '../images/twitter.png'
import linkedin from '../images/linkedin.png'
import facbook from '../images/facbook.png'
import ScrollAnimation from 'react-animate-on-scroll';

function Footer() {
    return (
        <ScrollAnimation animateIn="animate__fadeInUp"
            // delay={50}
            animateOnce={true}
            animatePreScroll={false}>
            <div className="text-white mt-10 grid  bg-[#204485]">

                <h1 className='text-white my-10 text-center'>رفع المعايير وضمان السلامة درعنا للتميز</h1>

                <div className='flex justify-center gap-10'>
                    <button className='text-white p-1 border-[1px] border-[#CBAD4C] bg-[#CBAD4C]'>اقتراحات</button>
                    <button className='text-white p-1 border-[1px]'>استفسارات</button>
                </div>
                <div className="flex justify-center">

                </div>
                <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 my-8 pr-16">
                    <div className="grid grid-rows-6 font-light text-center lg:text-right md:text-right">
                        <h1 className='text-right font-black text-[#CBAD4C]'>تواصل معنا</h1>
                        <p className=' text-right' >Info@alhemayat.com</p>
                        <p className=' text-right' >0500039115</p>
                    </div>
                    <div className="grid grid-rows-4 font-light text-center lg:text-right md:text-right">
                        <h1 className=' text-right font-black text-[#CBAD4C]'>الشروط العامة</h1>
                        <p className='text-right'>معلومات عامة</p>
                        <p className='text-right'>سياسة الخصوصة</p>
                        <p className='text-right'>سياسة الإستخدام</p>

                    </div>
                    <div className="grid grid-rows-6 font-light text-center lg:text-right md:text-right">
                        <h1 className=' text-right font-black text-[#CBAD4C]'>ماذا نقدم لكم؟</h1>
                        <p className=' text-right' >الإستشارات الأمنية</p>
                        <p className=' text-right' >الدراسات الأمنية</p>
                        <p className=' text-right' >حماية الشخصيات</p>
                        <p className=' text-right' >حماية المرافق</p>
                        <p className=' text-right' >أجهزة الحماية الأمنية</p>
                    </div>
                    <div className="grid grid-rows-6 font-light text-center lg:text-right md:text-right ">
                        <h1 className='text-right font-black text-[#CBAD4C]'>الروابط الرئيسية</h1>
                        <p className=' text-right'>الشاشة الرئيسية</p>
                        <p className=' text-right'>من نحن ؟</p>
                        <p className=' text-right'>خدماتنا</p>
                        <p className=' text-right'>المقالات</p>
                        <p className=' text-right'>عملائنا</p>
                    </div>

                </div>
                <div class="border-b border-gray-300 mx-20 my-10"></div>

                <div className='grid grid-cols-1 place-items-center md:grid-cols-3 gap-10 font-black'>
                    <img className=' w-16 mb-8  ' src={logo} alt='' />
                    <h1>جميع الحقوق محفوظة لشركة الحمايات الأمنية 2023 ©</h1>
                    <div className='flex justify-center gap-4 mx-4 mb-4'>
                        <a href='shfskdfhskf'>
                            <img src={facbook} alt='' />
                        </a>
                        <a href='shfskdfhskf'>
                            <img src={twiter} alt='' />
                        </a>
                        <a href='shfskdfhskf'>
                            <img src={linkedin} alt='' />
                        </a>
                    </div>
                </div>
            </div>
        </ScrollAnimation>
    );
}

export default Footer