import React from 'react'
import './Hero.css'

const Hero = () => {
    return (
        <div className='hero'>
            <div className='content text-center'>
                <p> رفع المعايير"</p>
                <p><span>وضمان </span>السلامة</p>
                <p>"درعنا <span> للتميز</span></p>
            </div>
        </div>
    )
}

export default Hero
